import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
  scmAuthApiRef,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  githubAuthApiRef,
  // errorApiRef,
  identityApiRef,
  // createApiRef,
  storageApiRef,
} from '@backstage/core-plugin-api';
import {
  VisitsStorageApi,
  // VisitsWebStorageApi,
  visitsApiRef,
} from '@backstage/plugin-home';


export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  // ScmAuth.createDefaultApiFactory(),
  // Implementation that relies on a provided storageApi
  createApiFactory({
    api: visitsApiRef,
    deps: {
      storageApi: storageApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ storageApi, identityApi }) =>
      VisitsStorageApi.create({ storageApi, identityApi }),
  }),
  // https://backstage.io/docs/auth/#scaffolder-configuration-software-templates
  createApiFactory({
    api: scmAuthApiRef,
    deps: {
      githubAuthApi: githubAuthApiRef,
    },
    factory: ({ githubAuthApi }) => ScmAuth.forGithub(githubAuthApi),
  }),

  // // Or a localStorage data implementation, relies on WebStorage implementation of storageApi
  // createApiFactory({
  //   api: visitsApiRef,
  //   deps: {
  //     identityApi: identityApiRef,
  //     errorApi: errorApiRef
  //   },
  //   factory: ({ identityApi, errorApi }) => VisitsWebStorageApi.create({ identityApi, errorApi }),
  // }),
];
