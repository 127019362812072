import React from 'react';
import { Route } from 'react-router-dom';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
  CatalogTable, // custom columns
  CatalogTableColumnsFunc, // custom columns
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage/plugin-tech-radar';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';
import { AlertDisplay, OAuthRequestDialog } from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';

// adding sign-in provider to the frontend
import { githubAuthApiRef } from '@backstage/core-plugin-api';
import { SignInPage } from '@backstage/core-components';
import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { HomePage } from './components/home/HomePage';
import { VisitListener } from '@backstage/plugin-home';

import type { IdentityApi } from '@backstage/core-plugin-api';
import { discoveryApiRef, useApi } from '@backstage/core-plugin-api';
import { setTokenCookie } from './cookieAuth';

const app = createApp({
  apis,
  components: {
    SignInPage: props => {
      const discoveryApi = useApi(discoveryApiRef);
      return (
        <SignInPage
        {...props}       
        provider={{
          id: 'github', // Unique ID for the provider.
          title: 'GitHub', // Display name for the sign-in provider.
          message: 'Sign in with GitHub', // Custom message for the sign-in button.
          apiRef: githubAuthApiRef, // Reference to the GitHub auth API.
        }}
        onSignInSuccess={async (identityApi: IdentityApi) => {
          // On successful sign-in, set token cookie.
          await setTokenCookie(
            await discoveryApi.getBaseUrl('cookie'), // Get the auth base URL.
            identityApi, // Pass the signed-in user's identity API.
          );
          // Call the original onSignInSuccess handler if it exists.
          if (props.onSignInSuccess) {
            props.onSignInSuccess(identityApi);
          }
        }}
      />
    );
  },
},
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
});

// // custom columns added dynamically
const myColumnsFunc: CatalogTableColumnsFunc = entityListContext => {

  // create a list of keys for all labels
  const labelList: string[] = [];
  entityListContext.entities.forEach(entity => {
    if (entity.metadata.labels === undefined) {
      return;
    }
    Object.keys(entity.metadata.labels).forEach(key => {
      if (!labelList.includes(key)) {
        labelList.push(key);
      }
    });
  });

  // Map the labelList to TableColumn objects
  const customColumns = labelList.map(key => 
    CatalogTable.columns.createLabelColumn(key, { title: key.charAt(0).toUpperCase() + key.slice(1) })
  );

  // Combine the default columns with the new label columns
  return [
    ...CatalogTable.defaultColumnsFunc(entityListContext),
    ...customColumns,
  ];
};
const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>
    {/* <Route path="/catalog" element={<CatalogIndexPage />} /> */}
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
      </TechDocsAddons>
    </Route>
    <Route path="/create" element={<ScaffolderPage />} />
    <Route path="/catalog" element={<CatalogIndexPage columns={myColumnsFunc} />} />
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/tech-radar"
      element={<TechRadarPage width={1500} height={800} />}
    />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
    <VisitListener />
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
